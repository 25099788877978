import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
}

export const Stripe = memo(function Stripe({ languageCode }: Props) {
  return (
    <Container dial={4} row space="between" tag="section">
      <LeftSide>{useVocabularyData('stripe-text', languageCode)}</LeftSide>

      <RightSide dial={6} row>
        <Anchor aria-label="Coesione Italia">
          <Logo
            alt="logo Coesione Italia"
            loading="lazy"
            src="/coesione-italia.png"
            width="60"
            height="75"
          />
        </Anchor>

        <Anchor aria-label="Cofinanziato UE">
          <Logo
            alt="logo Cofinanziato UE"
            loading="lazy"
            src="/cofinanziato-ue.png"
            width="78"
            height="75"
          />
        </Anchor>

        <Anchor aria-label="Repubblica Italiana">
          <Logo
            alt="logo Repubblica Italiana"
            loading="lazy"
            src="/repubblica-italiana.png"
            width="69"
            height="75"
          />
        </Anchor>

        <Anchor aria-label="Regione Veneto">
          <Logo
            alt="logo Regione Veneto"
            loading="lazy"
            src="/regione-veneto.png"
            width="97"
            height="75"
          />
        </Anchor>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  padding: 2rem 0;
  margin-top: 2rem;
  border-top: 0.0625rem solid ${rgba(theme.colors.variants.neutralDark1, 0.1)};
  gap: 3rem;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 2.25rem;
    padding: 2.25rem 2rem 0;
    gap: 0;
  }
`

const LeftSide = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
  text-align: start;

  @media (max-width: 991px) {
    width: 100%;
    text-align: center;
  }
`

const RightSide = styled(FlexBox)`
  gap: 0 3.75rem;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 2rem;
    gap: 2rem;
    flex-direction: column;
  }
`

const Anchor = styled.div`
  display: inline-flex;
  align-items: center;

  &::before {
    content: '';
    display: block;
    width: 0.125rem;
    height: 4.375rem;
    background-color: ${({ theme }) => theme.colors.variants.neutralDark1};
    margin-right: 3.75rem;
  }

  &:first-of-type {
    &::before {
      content: none;
    }
  }

  @media (max-width: 991px) {
    &::before {
      content: none;
    }
  }
`

const Logo = styled.img``
